import React, { useState } from "react"
import { graphql } from "gatsby"
import Collapse from "@kunukn/react-collapse"
import Layout from "../components/layout/layout"
import SEO from "../components/util/seo"
import ColumnContent from "../components/content/columnContent";
import "../assets/scss/layout/pages/agency.scss"

const AgencyPage = ({ data, path }) => {
  const content = data.wpPage

  const [isOpen, setIsOpen] = useState({})

  return (
    <Layout mt={true} path={path}>
      <SEO title={content.title} />
      <div className={'agency justify-content-between flex-md-row-reverse flex-column-reverse'}>
        <ColumnContent contentEN={content.ACF.content} contentFR={content.content} title={content.title} />

        <div className={'paragraphs paragraphs__responsive col-4 d-md-none'}>
          {content.ACF.paragraphs.map((paragraph, index) => (
            <>
              <h2 className={'title__paragraph'}>{paragraph.paragraphTitle}</h2>
              <p className={'content__paragraph'}>{paragraph.paragraphContent}</p>
            </>
          ))}
        </div>

        <div className={'paragraphs col-4 d-none d-md-block'}>
          {content.ACF.paragraphs.map((paragraph, index) => (
            <>
              <button className={'title__paragraph'} onClick={() => setIsOpen(isOpen === index ? null : index)}>
                <h2 className={`${isOpen === index && 'active'}`}>{paragraph.paragraphTitle}</h2>
              </button>
              <Collapse className={'content__paragraph'} isOpen={isOpen === index}>
                <p>{paragraph.paragraphContent}</p>
              </Collapse>
            </>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    wpPage(slug: {eq: "agency"}) {
      ACF {
        content
        paragraphs {
          paragraphTitle
          paragraphContent
        }
      }
      content
      title
    }
  }
`

export default AgencyPage

